body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

}
h1,h2,h3,h4,h5,h6, p,span, button, div{
  font-family: 'Montserrat';
}
.row{
  margin: 0 !important;
}
#root{
  width: 100%;
  height: 100%;
}
code {
  font-family: 'Montserrat', sans-serif !important;
    /* monospace; */
}
